<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-card elevation="0" class="d-flex flex-column event-Item">
    <router-link :to="to">
      <div class="event-Item_imgBox">
        <v-img
          cover
          class="event-Item_imgBox_img"
          width="100%"
          aspect-ratio="1.777"
          :src="photoUrl"
          :alt="photoName"
        ></v-img>
      </div>
      <div class="event-Item_textBox">
        <h2 class="event-Item_textBox_title text-overflow-ep-2">
          {{ name }}
        </h2>
        <template v-if="hasInfoIcon">
          <Info-icon
            textClass="event-Item_textBox_span text-overflow-ep-2"
            :text="range"
            filename="time.svg"
          />
          <Info-icon
            textClass="event-Item_textBox_span text-overflow-ep-2"
            :text="location"
            filename="place.svg"
          />
        </template>
        <template v-else>
          <div v-html="content" class="mb-4"></div>
        </template>
      </div>
    </router-link>
  </v-card>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import itemMixins from "@/components/page/indexItem.js";
export default {
  mixins: [itemMixins],
  props: {
    hasInfoIcon: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    InfoIcon: () => import("./infoIcon.vue"),
  },
  computed: {
    location() {
      if (!this.item) return "";
      return this.item.location;
    },
    range() {
      return this.$helper.formatDateRange(this, this.item, true)
    },
    to() {
      if (!this.item) return "";
      return {
        name: "cloud-detail",
        params: {
          site_id: this.site_id,
          code: this.code,
        },
      };
    },
  },
};
</script>